<template>
    <v-container id="dashboard" fluid tag="section">
        <v-row class="px-5">
            <v-col cols="8">
                <v-card class="user-info mb-2 py-5 px-12" color="#fac74a" height="195" >
                    <!-- <v-avatar height="64" width="64" class="mr-6">
                        <img src='https://assets.koocoding.com/common/images/default_avatar.svg' />
                    </v-avatar> -->
                    <div class="content">
                        <div class="header-bg">
                            <img :src="headerBg" alt="">
                        </div>

                        <div class="line">
                            <div class="hello">老师 你好！</div>
                        </div>
                        <div class="line">
                            <div class="slogan">创造美好生活，元气满满每一天！</div>
                        </div>
                        
                        <div class="split-line"></div>

                        <div class="line">
                            <div class="text-1" v-show="userName != ''">{{ userName }}</div>
                            <div class="text-2">{{ userInfo.phone }}</div>
                        </div>

                        <div class="line">
                            <v-chip color="pink" label text-color="white" 
                                class="mr-3"
                            >
                                <v-icon left> mdi-crosshairs-gps </v-icon>
                                {{ userInfo.institution_name }}
                            </v-chip>
                            <v-btn 
                                dark rounded small 
                                color="pink" 
                                @click.stop="openResetPwDlg"
                            >
                                <v-icon left>mdi-pencil</v-icon>
                                修改密码
                            </v-btn>
                        </div>
                    </div>
                </v-card>
                <!-- <v-card>
                    <vc-calendar is-expanded :attributes='attributes' />
                </v-card> -->
            </v-col>
            <v-col cols="4">
                <v-card class="upgrade pa-5" color="#103754" height="195" >
                    <div class="upgrade-logo">
                        <img :src="upgradeLogo" >
                    </div>
                    <div class="upgrade-content">
                        <div class="line">
                            <div class="text-1">账号类型</div>
                            <div class="text-2">个人账号</div>
                        </div>
                        <div class="line">
                            <div class="text-1">到期时间</div>
                            <div class="text-2">2023-03-01</div>
                        </div>
                        <v-btn
                            color="red"
                            class="ma-2 white--text"
                        >
                            续期 / 升级
                            <v-icon right dark> mdi-package-up </v-icon>
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="px-5 mb-3">
            <v-col cols="12">
                <v-card>
                    <vc-calendar
                        class="custom-calendar max-w-full"
                        :masks="masks"
                        :attributes="attributes"
                        disable-page-swipe
                        is-expanded
                    >
                        <template v-slot:day-content="{ day, attributes }">
                            <div class="flex flex-col h-full z-10 overflow-hidden">
                            <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                            <div class="flex-grow overflow-y-auto overflow-x-auto">
                                <p
                                v-for="attr in attributes"
                                :key="attr.key"
                                class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                                :class="attr.customData.class"
                                >
                                {{ attr.customData.title }}
                                </p>
                            </div>
                            </div>
                        </template>
                    </vc-calendar>
                </v-card>
            </v-col>
        </v-row>

        <ResetPwDlg ref="resetDlg" @submit="submitResetPassword" />

    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { adminResetPassword } from "@/api/user";
import ResetPwDlg from "@/views/components/dialog/ResetPwDlg";

export default {
    name: "Profile",

    data() {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        return {
            masks: {
                weekdays: 'WWW',
            },
            attributes: [],
            // attributes: [
            //     {
            //     key: 1,
            //     customData: {
            //         title: 'Lunch with mom.',
            //         class: 'bg-red-600 text-white',
            //     },
            //     dates: new Date(year, month, 1),
            //     },
            //     {
            //     key: 2,
            //     customData: {
            //         title: 'Take Noah to basketball practice',
            //         class: 'bg-blue-500 text-white',
            //     },
            //     dates: new Date(year, month, 2),
            //     },
            //     {
            //     key: 3,
            //     customData: {
            //         title: "Noah's basketball game.",
            //         class: 'bg-blue-500 text-white',
            //     },
            //     dates: new Date(year, month, 5),
            //     },
            //     {
            //     key: 4,
            //     customData: {
            //         title: 'Take car to the shop',
            //         class: 'bg-indigo-500 text-white',
            //     },
            //     dates: new Date(year, month, 5),
            //     },
            //     {
            //     key: 4,
            //     customData: {
            //         title: 'Meeting with new client.',
            //         class: 'bg-teal-500 text-white',
            //     },
            //     dates: new Date(year, month, 7),
            //     },
            //     {
            //     key: 5,
            //     customData: {
            //         title: "Mia's gymnastics practice.",
            //         class: 'bg-pink-500 text-white',
            //     },
            //     dates: new Date(year, month, 11),
            //     },
            //     {
            //     key: 6,
            //     customData: {
            //         title: 'Cookout with friends.',
            //         class: 'bg-orange-500 text-white',
            //     },
            //     dates: { months: 5, ordinalWeekdays: { 2: 1 } },
            //     },
            //     {
            //     key: 7,
            //     customData: {
            //         title: "Mia's gymnastics recital.",
            //         class: 'bg-pink-500 text-white',
            //     },
            //     dates: new Date(year, month, 22),
            //     },
            //     {
            //     key: 8,
            //     customData: {
            //         title: 'Visit great grandma.',
            //         class: 'bg-red-600 text-white',
            //     },
            //     dates: new Date(year, month, 25),
            //     },
            // ],
            // headerBg: require("@/assets/images/admin/header-bg.png"),
            headerBg: require("@/assets/images/admin/header-bg-2.png"),
            upgradeLogo: require("@/assets/images/admin/rocket.png"),
        };
    },
    computed: {
        ...mapGetters([
            'userInfo',
        ]),
        userName: function() {
            let username = ''
            if (this.userInfo.name != '') {
                username = this.userInfo.name
            } else if (this.userInfo.username != '') {
                username = this.userInfo.username
            } else if (this.userInfo.number != '') {
                username = this.userInfo.number
            } else {
                username = '我的账号'
            }
            return username
        },
    },
    mounted() {
        // 加载userInfo
        // this.loadUserInfo();
    },
    methods: {
        complete(index) {
            this.list[index] = !this.list[index];
        },
        async loadUserInfo() {
            await this.$store.dispatch("user/getUserInfo");
        },
        openResetPwDlg() {
            this.$refs.resetDlg.openDlg();
        },
        async submitResetPassword(result) {
            let res = await adminResetPassword({
                oldPassword: result.oldPassword,
                newPassword: result.newPassword,
                newPassword2: result.newPassword2
            })
            if (res.msg == 'SUCCESS') {
                this.$store.dispatch('web/SetAlert', {
                    type: 'success',
                    msg: '修改密码成功'
                });
            } else {
                this.$store.dispatch('web/SetAlert', {
                    type: 'error',
                    msg: '旧密码错误，请重新输入'
                });
            }
        }
    },
    components: {
        ResetPwDlg
    }
};
</script>

<style lang="scss" scoped>
.line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}
.user-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #ddd;

    .content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        
        .split-line {
            width: 230px;
            line-height: 1px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            margin-top: 5px;
            margin-bottom: 15px;
        }

        .hello {
            font-size: 20px;
            font-weight: bold;
            color: #333;
        }
        .slogan {
            font-size: 15px;
            font-weight: bold;
            margin-right: 12px;
            color: #333;
            letter-spacing: .5px;
        }
        .text-1 {
            font-size: 15px;
            margin-right: 12px;
            color: #333;
            letter-spacing: .5px;
        }
        .text-2 {
            font-size: 14px;
            color: #444;
            // font-weight: 300;
            font-weight: bold;
            letter-spacing: 1px;
        }

        .header-bg {
            position: absolute;
            right: 10px;
            bottom: 0px;
            height: 170px;
            img {
                height: 100%;
            }
        }
    }
}
.upgrade {
    display: flex;
    justify-content: center;
    align-items: center;

    .upgrade-logo {
        height: 120px;
        margin-right: 25px;
        img {
            height: 100%;
        }
    }
    .upgrade-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .text-1 {
            font-size: 15px;
            font-weight: bold;
            margin-right: 12px;
            color: #efefef;
            letter-spacing: .5px;
        }
        .text-2 {
            font-size: 14px;
            color: #efefef;
            font-weight: 300;
            letter-spacing: 1px;
        }
    }
}
</style>

<style lang="scss">
.custom-calendar.vc-container {
    ::-webkit-scrollbar {
       width: 0px;
    }
    ::-webkit-scrollbar-track {
        display: none;
    }
    border-radius: 0;
    width: 100%;
    .vc-header {
        // background-color: #f1f5f8 !important;
        background-color: #f1f5f8;
        padding: 10px 0;
    }
    .vc-weeks {
        padding: 0;
    }
    .vc-weekday {
        background-color: #f8fafc;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea;
        padding: 5px 0;
    }
    .vc-day {
        padding: 0 5px 3px 5px;
        text-align: left;
        height: 90px;
        min-width: 90px;
        background-color: white;
        &.weekday-1,
        &.weekday-7 {
        background-color: #eff8ff;
        }
        &:not(.on-bottom) {
        border-bottom: 1px solid #b8c2cc;
        &.weekday-1 {
            border-bottom: 1px solid #b8c2cc;
        }
        }
        &:not(.on-right) {
        border-right: 1px solid #b8c2cc;
        }
    }
    .vc-day-dots {
        margin-bottom: 5px;
    }

    .text-white {
        color: #fff;
    }
    .bg-red-600 {
        background-color: #e53e3e;
    }
    .bg-blue-500 {
        background-color: #4299e1;
    }
    .bg-indigo-500 {
        background-color: #667eea;
    }
    .bg-teal-500 {
        background-color: #38b2ac;
    }
    .bg-pink-500 {
        background-color: #ed64a6;
    }
    .bg-orange-500 {
        background-color: #ed8936;
    }
}
</style>
