<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="400">
            <v-card class="px-4">
                <v-card-title>
                    <span class="title-text">修改密码</span>
                </v-card-title>
                <v-row justify="center">
                <!-- <v-row> -->
                    <v-col cols="12" class="px-10">
                    <!-- <v-col> -->
                        <form class="">
                            <v-text-field
                                label="旧密码"
                                v-model="oldPassword"
                                type="password"
                                :counter="12"
                                :error-messages="oldPasswordErrors"
                                required
                                @input="$v.oldPassword.$touch()"
                            ></v-text-field>

                            <v-text-field
                                label="新密码"
                                v-model="newPassword"
                                type="password"
                                :counter="12"
                                :error-messages="newPasswordErrors"
                                required
                                @input="$v.newPassword.$touch()"
                            ></v-text-field>

                            <v-text-field
                                label="确认新密码"
                                v-model="newPassword2"
                                type="password"
                                :counter="12"
                                :error-messages="newPassword2Errors"
                                required
                                @input="$v.newPassword2.$touch()"
                            ></v-text-field>
                        </form>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-2">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="btn-text font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="btn-text font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength, sameAs } from "vuelidate/lib/validators";

export default {
    name: "ResetPwDlg",

    mixins: [validationMixin],
    validations: {
        oldPassword: { required, maxLength: maxLength(12), minLength: minLength(6) },
        newPassword: { required, maxLength: maxLength(12), minLength: minLength(6) },
        newPassword2: { sameAsPassword: sameAs('newPassword') },
    },

    data: function () {
        return {
            dialog: false,
            
            oldPassword: "",
            newPassword: "",
            newPassword2: "",
        }
    },
    computed: {
        oldPasswordErrors() {
            const errors = []
            if (!this.$v.oldPassword.$dirty) return errors
            !this.$v.oldPassword.minLength && errors.push("不能少于6个字符")
            !this.$v.oldPassword.maxLength && errors.push("不能超过12个字符")
            !this.$v.oldPassword.required && errors.push("请输入旧密码")
            return errors
        },
        newPasswordErrors() {
            const errors = []
            if (!this.$v.newPassword.$dirty) return errors
            !this.$v.newPassword.minLength && errors.push("不能少于6个字符")
            !this.$v.newPassword.maxLength && errors.push("不能超过12个字符")
            !this.$v.newPassword.required && errors.push("请输入新密码")
            return errors
        },
        newPassword2Errors() {
            const errors = []
            if (!this.$v.newPassword2.$dirty) return errors
            !this.$v.newPassword2.sameAsPassword && errors.push("两次输入的密码不一致")
            return errors
        },
    },
    methods: {
        openDlg(item = false) {
           this.clear()
            this.dialog = true
        },
        clear() {
            this.$v.$reset()

            this.oldPassword = ""
            this.newPassword = ""
            this.newPassword2 = ""
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.$emit("submit", {
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                    newPassword2: this.newPassword2
                })
                this.dialog = false
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.title-text {
    font-size: 16px;
    font-weight: bold;
}
.btn-text {
    font-size: 15px;
    font-weight: bold;
}
</style>
